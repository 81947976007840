<template>
  <div id="components">
    <div id="shows-component" class="component solo-component with-padding">
      <FutureShow v-if="futureShow" :show="futureShow" :is-individual="true" />
      <PastShow v-if="pastShow" :show="pastShow" :is-individual="true" />
    </div>
  </div>
</template>

<script>

import FutureShow from "@/components/FutureShow";
import PastShow from "@/components/PastShow";
import {futureShowDict} from "@/global/futureShowList";
import {pastShowDict} from "@/global/pastShowList";

export default {
  name: 'Show-View',
  components: {FutureShow, PastShow},
  data: (instance) => {
    const id = instance.$route.params.showId
    const futureShow = futureShowDict[id];
    if (futureShow) {
      return {futureShow, pastShow: null}
    }
    const pastShow = pastShowDict[id];
    if (pastShow) {
      return {pastShow, futureShow: null}
    }
  },
}
</script>

<style lang="scss" scoped>
  .ind-show-container {
    margin-bottom: 40px;
  }
</style>
